import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Layout, { Seo } from '../../../Layout';
import Cover from '../../../Elements/Cover';
import PageSection from '../../../Elements/PageSection';
import Image from '../../../Elements/Image';

import withTranslations from '../../../../i18n/withTranslations';

const Page = ({ pageContext: { entry, general } }) => {
  const { t } = useTranslation('vacature');
  const contentList = typeof entry.content !== 'undefined' ? entry.content : general.content;

  return (
    <Layout className="vacature plan">
      <Seo title={`${t('seoTitle')} - ${entry.title}`} />
      <Cover nowrap>
        <Image
          image={entry.image}
          className="cover-photo"
          style={{ position: 'fixed' }}
          imgStyle={{ objectPosition: 'center 30%' }}
        />
        <div className="wrapped">
          <PageSection center>
            <h1>{entry.title}</h1>
            {typeof entry.intro === 'undefined' ? (
              <p className="tldr">
                <span className="title">
                  TL
                  <span>;</span>
                  DR:&nbsp;
                </span>
                <span className="content">
                  {typeof entry.tldr !== 'undefined' ? entry.tldr : general.tldr}
                </span>
              </p>
            ) : (
              <p className="intro">{entry.intro}</p>
            )}
            <div className="plan">
              {contentList.map((item) => {
                const text = typeof entry[item] !== 'undefined' ? entry[item] : general.parts[item];
                const { content, title = false, list = false } = text;

                return (
                  <div key={item}>
                    {title && <h6>{title}</h6>}
                    {content.map((paragraph, index) => {
                      const key = `${item}.p.${index.toString()}`;

                      if (paragraph.indexOf('<mail>') === -1 && paragraph.indexOf('<t>') === -1) {
                        return <p key={key}>{paragraph}</p>;
                      }

                      const parts = paragraph.split(/(<mail>|<\/mail>|<tel>|<\/tel>)/gi);
                      const replaced = parts
                        .map((part, partIndex) => {
                          if (
                            typeof parts[partIndex - 1] !== 'undefined'
                            && parts[partIndex - 1] === '<mail>'
                          ) {
                            return (
                              <a key={`m-${part}`} href={`mailto:${part}`}>
                                {part}
                              </a>
                            );
                          }

                          if (
                            typeof parts[partIndex - 1] !== 'undefined'
                            && parts[partIndex - 1] === '<tel>'
                          ) {
                            return (
                              <a key={`t-${part}`} href={`tel:${part.replace(/\s/g, '')}`}>
                                {part}
                              </a>
                            );
                          }

                          return part;
                        })
                        .filter((part) => !['<mail>', '</mail>', '<tel>', '</tel>'].includes(part));

                      return <p key={key}>{replaced}</p>;
                    })}
                    {list && (
                      <ul className="list">
                        {list.map((listItem, index) => {
                          const key = `${item}.list.${index.toString()}`;

                          return <li key={key}>{listItem}</li>;
                        })}
                      </ul>
                    )}
                  </div>
                );
              })}
            </div>
          </PageSection>
        </div>
      </Cover>
    </Layout>
  );
};

Page.propTypes = {
  pageContext: PropTypes.shape({
    entry: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
    general: PropTypes.object.isRequired, // eslint-disable-line react/forbid-prop-types
  }).isRequired,
};

export default withTranslations(Page);
